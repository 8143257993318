<template>
  <div class="normal-text bg-dark-theme text-center">
    <div class="bg-1" :class="{'image-loading': !bg1ImageLoaded}">
      <div class="d-block m-0">
        <LandingPageHeader :trasparentHeader="true" />
        <div class="clearfix"></div>
      </div>
      <div v-if="!bg1ImageLoaded" class="main_loader">
        <div class="img-loader"></div>
      </div>
      <div class="h-md-8vh"></div>
      <section class="landing_view">
        <div class="row">
          <div class="col-12 col-md-8 mx-md-auto">
            <div class="h-15vh h-md-70vh"></div>
            <div class="row">
              <div class="col-12">
                <h1 class="large-text fnt-tny">{{ $t('BECOME A MOM AFFILIATE') }}</h1>
                <h2 class="large-text-2 px-1 px-md-3">
                  {{ $t('Earn commission when you refer new users to MOM') }}.
                </h2>
              </div>
              <div class="col-12 d-flex justify-content-center">
                <b-link class="custom-design custom-design-black px-md-3 font-weight-bold tra-black-bg" :to="userRedirect">{{ $t('GET STARTED TODAY') }}.</b-link>
              </div>
              <div class="h-md-10vh"></div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <section class="landing_section">
      <div class="row">
        <div class="col-12">
          <h1 class="large-text-1 fnt-tny text-light text-center text-uppercase">{{ $t('How to use the MOM AI Referral Program') }}</h1>
        </div>
        <div class="col-12">
          <p>
            {{ $t('Welcome to the MOM AI Referral Program!') }} {{ $t('Follow these simple steps to sign up and start earning money by referring others to our services') }}.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row">
            <div class="col-12 col-md-4" v-for="(widget, wk) in landingWidgets" :key="`widget_${wk}`">
              <div class="ai_widget_img">
                <b-img :src="`${$apiUrl}/images/landing/affilate/${widget.img}`" class="home_img" fluid />
              </div>
              <b-link class="i_widget_label custom-design mx-auto text-uppercase" style="min-width: 80%;" :to="userRedirect">{{ wk+1 }}. {{ widget.title }}</b-link>
              <div class="text-left ai_widget_text mx-auto px-3">
                <template v-if="!isEmpty(widget.text)">
                  <p>
                    {{ widget.text }}.
                  </p>
                </template>
                <template v-if="!isEmpty(widget.ol)">
                  <ol start="1" class="pl-1">
                    <li v-for="(ol, olk) in widget.ol" :key="`widget_olk_${olk}`">
                      {{ ol.text }}.
                    </li>
                  </ol>
                </template>
                <template v-if="!isEmpty(widget.ul)">
                  <ul class="ml-0 pl-1">
                    <li v-for="(ul, ulk) in widget.ul" :key="`widget_ulk_${ulk}`">
                      {{ ul }}.
                    </li>
                  </ul>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="landing_section text-left">
      <div class="row mb-5">
        <div class="col-12">
          <h1 class="large-text-1 fnt-tny text-light text-center text-uppercase">{{ $t('DISCOVER OUR PRODUCTS') }}</h1>
        </div>
      </div>
      <div class="row mb-4 mb-md-1" v-for="(section, sk) in landingPageTiels" :key="`l_section_${sk}`">
        <div v-if="sk > 0" class="h-md-10vh"></div>
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row d-flex justify-content-around align-items-center">
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-10 mx-auto">
                  <b-img :src="`${$apiUrl}/images/landing/Website/${section.img}`" class="home_img" fluid :alt="section.imgAlt" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="row mb-md-3 mb-1 mt-2 mt-md-0">
                <div class="col-12 text-center">
                  <b-link class="custom-design text-light px-md-5 font-weight-bold" :to="{name: section.route}">{{ section.title }}</b-link>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="mb-1">{{ section.text }}.</p>
                  <template v-if="!isEmpty(section.ul)">
                    <p class="mb-0 mt-0 pb-0">{{$t('Suitable for')}}:</p>
                    <ul class="ml-0 pl-1">
                      <li v-for="(ul, spk) in section.ul" :key="`${spk}_${sk}_sec_para`">
                        {{ ul }}.
                      </li>
                    </ul>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h-md-10vh"></div>
    </section>

    <section class="landing_section text-left">
      <div class="row mb-5">
        <div class="col-12">
          <h1 class="large-text-1 fnt-tny text-light text-center text-uppercase">{{ $t('HEAR FROM OUR SUCCESSFUL AFFILIATES') }}</h1>
        </div>
      </div>
      <div class="row mb-4 mb-md-1" v-for="(section, sk) in landingPageTiels2" :key="`l_section_${sk}`">
        <div v-if="sk > 0" class="h-md-10vh"></div>
        <div class="col-12 col-md-11 mx-md-auto">
          <div class="row d-flex justify-content-around align-items-center">
            <div class="col-12 col-md-4">
              <div class="row">
                <div class="col-10 mx-auto">
                  <b-img :src="`${$apiUrl}/images/landing/affilate/${section.img}`" class="home_img" fluid :alt="section.imgAlt" />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="row mb-1 mt-2 mt-md-0">
                <div class="col-12">
                  <h2 class="large-text-2">{{ section.title }}</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-12 clr-grey">
                  <p class="mb-1 clr-grey">{{ section.text }}.</p>
                  <template v-if="!isEmpty(section.ul)">
                    <p class="mb-0 clr-grey mt-0 pb-0">{{$t('Suitable for')}}:</p>
                    <ul class="ml-0 pl-1 clr-grey">
                      <li v-for="(ul, spk) in section.ul" :key="`${spk}_${sk}_sec_para`">
                        {{ ul }}.
                      </li>
                    </ul>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="landing_section text-center">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10">
          <h2 class="large-text-2 px-1 px-md-3">
            {{ $t('These stories demonstrate how becoming a MOM Affiliate Partner can significantly boost revenue and provide new avenues for growth and success') }}. {{ $t('Join our affiliate program today and start generating revenue with MOM App!') }}.
          </h2>
        </div>
      </div>
      <div class="row justify-content-center mt-3">
        <div class="col-12 col-md-10">
          <h2 class="large-text-2 px-1 px-md-3">
            {{ $t('Transform your network into net-worth') }}! {{ $t('Earn juicy commissions when you invite friends and others to subscribe') }}!
          </h2>
        </div>
      </div>
      <div class="row justify-content-center mt-1">
        <div class="col-12 col-md-10">
          <b-link class="ai_widget_label custom-design mx-auto" :to="userRedirect">{{ $t('SIGNUP NOW') }}.</b-link>
        </div>
      </div>
    </section>

    <LandingPageFooter :trasparentFooter="true" />
    <AppFooterBlack :trasparentFooter="true" />
  </div>
</template>

<script>
import LandingPageFooter from '@core/layouts/components/LandingPageFooter.vue'
import AppFooterBlack from '@core/layouts/components/AppFooterBlack.vue'
import LandingPageHeader from '@core/layouts/components/LandingPageHeader.vue'
import LandingSVG from '@/views/LandingSVG.vue'
import SubscriptionList from '@/views/shared/subscription/SubscriptionList.vue'
import { mapGetters, mapActions } from 'vuex'
import { isEmpty, imgLoad } from '@/utilities'

export default {
  name: "UserLandingPage",
  components: {
    LandingSVG,
    LandingPageFooter,
    AppFooterBlack,
    LandingPageHeader,
    SubscriptionList
  },
  data: () => ({
    bg1ImageLoaded: false
  }),
  directives: {
    lazyBackground: {
      bind: (el, binding) => {
        el.style.backgroundImage = `url('${binding.value}')`
      }
    }
  },
  computed: {
    ...mapGetters('subscriptionPlan', [
        'isSubscriptionLoaded',
        'userSubscriptions',
        'isSubscriptionCountryLoaded',
        'countries',
    ]),
    userRedirect: {
      get(){
        if(window.SuperApp.getters._isUser()){
          return {name: 'user-dashboard'}
        }
        return {name: 'apps-login'}
      }
    },
    shopRedirect: {
      get(){
        if (window.SuperApp.getters._isStore()){
          return { name: 'shop-dashboard' }
        }
        return {name: 'apps-shop-login'}
      }
    },
    providerRedirect: {
      get(){
        if (window.SuperApp.getters._isProvider() && !window.SuperApp.getters._isAgent()){
          return { name: 'provider-requests'}
        }
        else if (window.SuperApp.getters._isProvider() && window.SuperApp.getters._isAgent()){
          return { name: 'provider-agent-dashboard' }
        }
        return {name: 'apps-provider-login'}
      }
    },
    landingWidgets: {
      get(){
        return [
          {
            img: 'Step 1.webp',
            title: this.$t('COPY YOUR REFERRAL LINK'),
            ol: [
              {
                text: this.$t('Log in to your MOM account'),
                route: 'user-affiliation-overview'
              }, 
              {
                text: this.$t('Navigate to the "Affiliate Program" section'),
              },
              {
                text: this.$t('Copy your unique referral link'),
              },
            ]
          },
          {
            img: 'Step 2.webp',
            title: this.$t('SHARE YOUR REFERRAL LINK'),
            ol: [],
            ul: [
              this.$t('Social media (Instagram, Twitter, etc)'),
              this.$t('Email'),
              this.$t('Blogs and forums'),
              this.$t('Direct messages'),
            ],
            text: this.$t('Share your referral link with friends, family, and others who might benefit from MOM app services') + '. ' + this.$t('You can share it via') + ':',
          },
          {
            img: 'Step 3.webp',
            title: this.$t('EARN MONEY ON SUBSCRIPTIONS'),
            ol: [],
            ul: [],
            text: this.$t('When someone uses your referral link to subscribe to MOM app, you earn a commission on their subscription'),
          },
        ]
      }
    },
    servicesList: {
      get(){
        return [
          {image: 'ai-chat.webp', title: this.$t('ai chat')},
          {image: 'ai-image-generator.webp', title: this.$t('ai image generator')},
          {image: 'ai-code.webp', title: this.$t('ai code generator')},
          {image: 'ai-text-to-speech.webp', title: this.$t('ai text to speech')},
          {image: 'ai-speech-to-text.webp', title: this.$t('ai speech to text')},
          {image: 'ai-writer.webp', title: this.$t('ai writer')},
        ]
      }
    },
    landingPageTiels: {
      get(){
        return [
          {
            img: 'MOM AI.webp',
            imgAlt: "MOM AI",
            title: this.$t('MOM AI - ASK ANYTHING'),
            route: 'apps-login',
            text: this.$t('MOM AI is an AI-powered content generator and personal assistant designed to handle all your marketing needs') + '. ' + this.$t('Whether you need blog content, email templates, social media posts, video content, or website content, MOM AI can create it all efficiently'),
            ul: [
              this.$t('Content creators'),
              this.$t("Marketing agencies"),
              this.$t("Bloggers"),
              this.$t("Small businesses"),
              this.$t("E-commerce stores"),
            ],
          },
          {
            img: 'MOM Shop.webp',
            imgAlt: "MOM Shop",
            title: this.$t('MOM SHOP - SELLER APP'),
            route: 'apps-shop-login',
            text: this.$t('MOM Shop revolutionizes the selling experience by enabling sellers to create their own website and go online in just 60 seconds') + '. ' + this.$t('It offers comprehensive features like ordering, HRM, Accounting, AI Marketing, delivery and inventory management'),
            ul: [
              this.$t('Restaurants & Cloud Kitchen'),
              this.$t("E-commerce businesses"),
              this.$t("Retail stores"),
              this.$t("Artisans and craftsmen"),
              this.$t("Digital product sellers"),
            ],
          },
          {
            img: 'MOM Service.webp',
            imgAlt: "MOM Service",
            title: this.$t('MOM PROVIDER - BOOKING APP'),
            route: 'apps-provider-login',
            text: this.$t('MOM Provider is tailored for service providers to grow their business with unique features such as assigning agents directly to customer locations, adding additional services on-site, and secure payment processing') + '. ' + this.$t('It\'s designed to streamline operations and enhance customer service'),
            ul: [
              this.$t('Laundry'),
              this.$t("Salon & Spa"),
              this.$t("Home service providers (eg, cleaning, plumbing, electrical)"),
              this.$t("Photographer"),
              this.$t("Pet Services"),
            ],
          },
        ]
      }
    },
    landingPageTiels2: {
      get(){
        return [
          {
            img: 'adorable-cartoon-style-A-young-woman-with-short-da.webp',
            imgAlt: "MOM AI",
            title: this.$t('DOUBLING REVENUE WITH MOM AFFILIATE PROGRAM'),
            route: 'apps-login',
            text: this.$t('Emily started promoting MOM App on her e-commerce blog, highlighting its features and benefits to her audience') + '. ' + this.$t('As a MOM Affiliate Partner, she earned commissions for every new user who signed up through her referral link') + '. ' + this.$t('Her engaging content and targeted marketing strategies led to a significant increase in sign-ups'),
            ul: [
              this.$t('Revenue Boost: Emily doubled her monthly earnings within 4 months'),
              this.$t("Audience Growth: 25% increase in blog traffic"),
              this.$t("Partnership Value: Earned exclusive perks and bonuses as a top performer"),
            ],
          },
          {
            img: 'adorable-cartoon-style-Create-a-cartoonstyle-illus.webp',
            imgAlt: "MOM Shop",
            title: this.$t('TRANSFORMING SOCIAL MEDIA INFLUENCE INTO PROFIT'),
            route: 'apps-shop-login',
            text: this.$t('Meet James, a Social Media Influencer') + '. ' + this.$t('James leveraged his large following on Instagram and TikTok to promote MOM App') + '. ' + this.$t('By creating engaging videos and posts demonstrating the app\'s features, he attracted a substantial number of followers to become MOM App users') + '. ' + this.$t('The MOM Affiliate Program\'s generous commission structure allowed James to turn his social media influence into a profitable venture'),
            ul: [
              this.$t('Revenue Increase: Tripled his income from affiliate marketing in 3 months'),
              this.$t("Follower Engagement: 40% increase in engagement rates"),
              this.$t("Brand Partnerships: Attracted new sponsorship deals due to increased credibility"),
            ],
          },
          {
            img: 'cartoon-style-Create-a-digital-illustrati.webp',
            imgAlt: "MOM Service",
            title: this.$t('MAXIMIZING PROFITS WITH TARGETED CAMPAIGNS'),
            route: 'apps-provider-login',
            text: this.$t('Meet Lisa, a Digital Marketing Expert Lisa joined the MOM Affiliate Program and used her expertise to create targeted ad campaigns') + '. ' + this.$t('She focused on reaching small business owners and service providers who could benefit most from MOM App\'s features') + '. ' + this.$t('Her strategic approach and data-driven tactics resulted in high conversion rates and substantial affiliate commissions'),
            ul: [
              this.$t('Revenue Growth: 150% increase in monthly affiliate earnings'),
              this.$t("Conversion Rate: Achieved a 20% conversion rate on targeted campaigns"),
              `${this.$t('Revenue Growth: 150% increase in monthly affiliate earnings')}`,
              `${this.$t("Conversion Rate: Achieved a 20% conversion rate on targeted campaigns")}`,
              `${this.$t("Marketing Success: Gained recognition as a top affiliate marketer, leading to additional business opportunities")}`,
            ],
          },
        ]
      }
    }
  },
  methods: {
    isEmpty,
    ...mapActions('subscriptionPlan', [
        'getSubscriptionPackages',
        'getCountries',
    ]),
    goToService(url) {
      if (url) {
        this.$router.push({
          path: url,
        })
      }
    },
    upgradeSubscription(subscription){
      this.$router.push({
        name: 'apps-registration'
      })
    }
  },
  async mounted(){
    const appUrl = process.env.VUE_APP_FRONT_END_BASE_URL
    const img = require('@/assets/images/bg-images/ai-landing-main.webp')
    const fullImageUrl = `${appUrl}${img}`
    imgLoad(fullImageUrl).then(r => {
      this.bg1ImageLoaded = true
    })
    if (!localStorage.getItem('userSelectedCountryId')) {
      try {
        const _ipInfo = await fetch('https://api.ipregistry.co/?key=dsnrdu52t7ii07ny')
        const ipInfo = await _ipInfo.json()
        if(!isEmpty(ipInfo?.location)){
          if(!this.isSubscriptionCountryLoaded){
            await this.getCountries().then(data => {
              if (data._statusCode === 200) {
                  const countries = data.responseData
                  const isCountryExist = countries.find(x => x.country_name == ipInfo.location.country.name)
                  if (isCountryExist) {
                    localStorage.setItem('userSelectedCountryId', isCountryExist.id)
                  }
              }
            })
          }else{
            const countries = this.countries
            const isCountryExist = countries.find(x => x.country_name === ipInfo.location.country.name)
            if (isCountryExist) {
              localStorage.setItem('userSelectedCountryId', isCountryExist.id)
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (!this.isSubscriptionLoaded) {
        await this.getSubscriptionPackages()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/landing-scoped.scss';

.bg-1 {
  background-image: url('~@/assets/images/bg-images/adorable-cartoon-style-A-diverse-group-of-happy-an.webp');
  background-position: center center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating the image */
  background-size: cover; /* Ensures the image covers the entire element */
  padding-bottom: 1.5rem;
}
</style>

<style lang="scss">
@import '~@core/scss/base/landing.scss';
@import '~@core/scss/base/landing-page.scss';
</style>
